import {atomFamily, useRecoilValue, useSetRecoilState} from "recoil";
import {LayerCollection, OverlayCollection} from "@hexlabsio/dasha-layers-sdk";
import axios from "axios";
import {MarkerCollection} from "@hexlabsio/dasha-markers-sdk";
import {LatLngBounds} from "leaflet";
import {jwtToken} from "./user/auth-state";
import {layerCollectionState} from "./layers/layer-state";
// eslint-disable-next-line import/no-cycle
import {markerCollectionState} from "./markers/marker-state";
import {CollectionResult, findBounds, findFeatureBounds, flyToLocation, SearchArea} from "./shared-state";

export type OverlayButtonResponse = {buttonIdentifier: string; overlay: OverlayCollection[]; bounds: LatLngBounds}

export const overlayCollectionState = atomFamily<OverlayButtonResponse[], {category: string}>({key: 'Overlays', default: () => []});

export function useCustomCollections(category: string) {
  const jwt = useRecoilValue(jwtToken)!;
  const flyTo = useSetRecoilState(flyToLocation);
  const setLayers = useSetRecoilState(layerCollectionState({category}));
  const setMarkers = useSetRecoilState(markerCollectionState({category}));
  const setOverlays = useSetRecoilState(overlayCollectionState({category}));
  return {
    async updateCollection(collection: string, url: string, searchedArea: SearchArea): Promise<CollectionResult> {
      try {
        const layers = await axios.get(`https://f5fw2daxzh.execute-api.eu-west-2.amazonaws.com/prod${url}`, {
          headers: {Authorization: `Bearer ${jwt}`},
          params: {
            latitude: searchedArea.lt,
            longitude: searchedArea.ln,
            radius: searchedArea.r,
            minLat: searchedArea.mlt,
            minLong: searchedArea.mln!,
            maxLat: searchedArea.xlt,
            maxLong: searchedArea.xln
          }
        })
        if (layers.status === 200) {
          const result = layers.data as LayerCollection | OverlayCollection | MarkerCollection;
          if((result as LayerCollection).features) {
            const layerCollection = result as LayerCollection;
            setLayers(old => {
              const update = old.find(it => it.buttonIdentifier === collection) ?? {buttonIdentifier: collection, layers: [], permanent: false};
              return [...old.filter(it => it.buttonIdentifier !== collection), {...update, layers: [layerCollection]}]
            });
            flyTo(findFeatureBounds(layerCollection.features.flatMap(it => it.features)));
            const sum = layerCollection.features.flatMap(it => it.features.length).reduce((total, count) => count + total, 0);
            return {total: sum, searchedArea}
          }
          if((result as MarkerCollection).member) {
            const markerCollection = result as MarkerCollection;
            setMarkers(old => {
              const update = old.find(it => it.buttonIdentifier === collection) ?? {buttonIdentifier: collection, markers: []};
              return [...old.filter(it => it.buttonIdentifier !== collection), {...update, markers: [markerCollection]}]
            });
            flyTo(findBounds(markerCollection.member.map(it => it.coordinate)));
            return {total: markerCollection.member.length}
          }
          if((result as OverlayCollection).overlay) {
            const overlayCollection = result as OverlayCollection;
            const bounds = new LatLngBounds([+searchedArea.mlt!, +searchedArea.mln!], [+searchedArea.xlt!, +searchedArea.xln!]);
            setOverlays(old => {
              const update = old.find(it => it.buttonIdentifier === collection) ?? {buttonIdentifier: collection, overlay: [], bounds};
              return [...old.filter(it => it.buttonIdentifier !== collection), {...update, bounds, overlay: [overlayCollection]}]
            });
          }
        }
        return {total: 1}
      } catch(e) {
        return {reason: 'ERROR'}
      }
    }
  }
}
