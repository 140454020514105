import axios from 'axios';
import qs from 'query-string';
import { v4 as uuid } from 'uuid';
import {CategoryApiSdk} from "@hexlabsio/dasha-categories-sdk";
import {MarkersApiSdk} from "@hexlabsio/dasha-markers-sdk";
import {LayersApiSdk} from "@hexlabsio/dasha-layers-sdk";

export const authenticatedAxios = (jwtToken: string, url?: string) => ({
    call: async (method: any, resource: any, path: string, body: any, pathParameters: any, queryParameters: any, multiQueryParameters: any, headers: any) => {
        const updatedHeaders = {
            ...headers,
            Authorization: `Bearer ${jwtToken}`,
            'X-Correlation-Id': uuid(),
        };
        const result = await axios(url + path, {
            method: method as any,
            data: body,
            paramsSerializer: (params) => qs.stringify(params),
            params: { ...queryParameters, ...multiQueryParameters },
            headers: updatedHeaders,
            transformResponse: [],
            validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
        });
        return {
            statusCode: result.status,
            body: result.data,
            headers: result.headers as any,
        };
    },
});

export const categorySdk = (jwtToken: string) => new CategoryApiSdk(authenticatedAxios(jwtToken, 'https://lfxyccl17e.execute-api.eu-west-2.amazonaws.com/prod'));
export const markersSdk = (jwtToken: string) => new MarkersApiSdk(authenticatedAxios(jwtToken, 'https://tfmo66ti37.execute-api.eu-west-2.amazonaws.com/prod'));
export const layersSdk = (jwtToken: string) => new LayersApiSdk(authenticatedAxios(jwtToken, 'https://f5fw2daxzh.execute-api.eu-west-2.amazonaws.com/prod'));
